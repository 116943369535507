import axios from "@/util/axios";
let {get, post } = axios
 export const hotLotteryInfos = data => post("/lottery/hotLotteryInfos",data)
//  转盘列表
export const getTurntableList = data => post("/info/turntable",data)
// 转动转盘
export const getZhuan = data => post("/info/zhuan",data)
// 转盘所有人的记录
export const turntableAllRecord = data => post("/info/turntableAllRecord",data)
// 当前用户的记录
export const turntableRecord = data => post("/info/turntableRecord",data)
// 转盘剩余次数
export const turntableTodayTimes = data => post("/info/turntableTodayTimes",data)
// 分享转盘
export const turntableShare = data => post("/info/turntableShare",data)
// 三方游戏列表
export const threeGameList = data => post("/thirdGame/list",data)
// 拉起三方游戏
export const getGameUrl = data => get("/thirdGame/getGameUrl",data)
// 下分
export const withdraw = data => post("/thirdGame/withdraw",data)
// 昨日总佣金
export const profile = data => post("/info/profile",data)
// 红包信息
export const redEnvelopeGet = data => post("/redEnvelope/get",data)
// 获取客服列表
export const customerService = data => post("/customerService/info",data)
// 首页弹窗站内信已读
export const siteMessageReadArr = data => post("/info/siteMessageRead",data)
// 收藏和取消收藏
export const favorGame = data => post("/info/favor/game",data)